/* Ensure all date cards are the same size */
.date-card {
    height: 200px; /* Set a fixed height for the parent card */
    max-height: 200px;
    overflow: auto;
}

.custom-badge {
    font-size: 0.65rem; /* Adjust font size */
}

.img-logo {
    width: 12rem;
    height: 12rem;
}

.img-arrow {
    width: 2rem;
    height: 2rem;
}

.img-plus {
    width: 5rem;
    height: 5rem;
}


  